import { NavLink } from "react-router-dom";
import "./home-feature-component.css";

const HomeFeatureComponent = (props) => {
  let direction = "row";
  let textAlign = "end";
  let paddingTop = "300px";

  if (props.imageFirst) {
    direction = "row-reverse";
    textAlign = "start";
  }

  if (props.first) {
    paddingTop = "120px";
  }

  let directionStyle = {
    flexDirection: direction,
    textAlign: textAlign,
    paddingTop: paddingTop,
  };

  return (
    <div className="feature-component" style={directionStyle}>
      <div className="left">
        <h3>{props.title}</h3>
        <p>{props.desc}</p>
        <NavLink to="/book">
          <button>Book now</button>
        </NavLink>
      </div>

      <div className="right">
        <img src={props.image}></img>
      </div>
    </div>
  );
};

export default HomeFeatureComponent;
