import "./home-welcome-components.css";
import parkingImage from "../../images/parking.svg";
import wifiImage from "../../images/wifi.png";
import poolImage from "../../images/pool.svg";
import grillImage from "../../images/grill.svg";
import mainVillaImage from "../../images/main-villa-image.svg";
import homeBackground from "../../images/home-background.svg";
import { NavLink } from "react-router-dom";

const HomeWelcomeComponent = () => {
  return (
    <div
      className="home-welcome"
      style={{ backgroundImage: `url(${homeBackground})` }}
    >
      <div className="left">
        <h1>Welcome to Villa Julija, villa with pool in Croatia</h1>
        <p>
          discover the beauty of the Croatian homeland, enjoy the sunny days by
          the pool or outdoor kitchen and grill
        </p>

        <NavLink to="/book">
          <button>Book now</button>
        </NavLink>

        <ul>
          <li>
            <img
              src={parkingImage}
              alt="image indicating that villa has parking"
            />
            <p>parking</p>
          </li>
          <li>
            <img src={wifiImage} alt="image indicating that villa has wifi" />
            <p>wi-fi</p>
          </li>
          <li>
            <img src={poolImage} alt="image indicating that villa has pool" />
            <p>pool</p>
          </li>
          <li>
            <img src={grillImage} alt="image indicating that villa has grill" />
            <p>grill</p>
          </li>
        </ul>
      </div>
      <div className="right">
        <img
          src={mainVillaImage}
          alt="an image showing villa julija pool and house"
        />
      </div>
    </div>
  );
};

export default HomeWelcomeComponent;
