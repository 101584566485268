import TestimonialsComponent from "../../components/testimonials/TestimonialsComponent";
import "./testimonials-layout.css";

const TesitmonialsLayout = () => {
  return (
    <div className="testimonials-layout">
      <h5 id="type">testimonials</h5>
      <h2>
        Read what others<br></br>have to say
      </h2>
      <div className="testimonials-container">
        <TestimonialsComponent
          review="Beautiful house, very nice owners, ready to help.

        The owners were very helpful, able to fulfil our wishes.
        There were some drinks and food ready at fridge while we arrived
        Pool is great, as well the house is newly renovated, fully equipped.
        You can pick some fruit directly from trees
        "
        />
        <TestimonialsComponent review="I definitely recommend it, perfect conditions, everything new, well-kept, very nice and helpful owners. Towels, bed linen, dishes in sets. The outdoor gazebo is a bull's eye. Not far from the beach, 5 minutes walk. Quiet neighborhood." />
        <TestimonialsComponent review="Beautiful house, everything clean and tidy. Gazebo with fridge, sun loungers, swimming pool, dishwasher fully equipped. Quiet neighborhood, close to the beach. A charming neighborhood. Refreshments from the owners. It was a very successful holiday. I highly recommend" />
        <TestimonialsComponent review="A very cool place. The rooms and the whole house are very well kept. Pool cleaned daily. The kitchen is fully equipped, even two bikes available. Owners very helpful and friendly. A great place to go for excursions, close to Trogir, Split, Makarska and many other charming places .. the welcome refreshment was delicious. I can recommend it with all my heart" />
        <TestimonialsComponent review="Great place, very neat. Amazing pool. The house has everything you need to live. Excitable owners who welcomed us with regional delicacies. We spent a fantastic 2 weeks there. I highly recommend it !!!" />
      </div>
    </div>
  );
};

export default TesitmonialsLayout;
