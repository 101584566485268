import "./welcome-about-component.css";
import aboutMainImage from "../../images/about-main-image.jpeg";

const WelcomeAboutComponent = () => {
  return (
    <div className="welcome-about-component">
      <div className="left">
        <h5>About us</h5>
        <h1>Welcome to villa julija</h1>
        <p>
          Stay at an apartment villa with a pool in Kastel Luksic, Croatia. This
          luxurious villa features spacious and comfortable apartments with all
          the amenities you need. Relax by the crystal-clear pool. The villa is
          located near the beach, allowing you to enjoy swimming in the Adriatic
          and experience a memorable holiday in this idyllic setting
        </p>
      </div>
      <div className="right">
        <img
          src={aboutMainImage}
          alt="an image showing villa julija pool and house"
        />
      </div>
    </div>
  );
};

export default WelcomeAboutComponent;
