import "./testimonials-component.css";
import quotationImage from "../../images/quotation.svg";

const TestimonialsComponent = (params) => {
  return (
    <div className="testimonials-component">
      <img src={quotationImage} alt="quatation for villa testimonial" />
      <p id="review">{params.review}</p>
    </div>
  );
};

export default TestimonialsComponent;
