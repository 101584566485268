import React, { Component } from "react";
import { Slide } from "react-slideshow-image";
import "./images-slider-component.css";
import "react-slideshow-image/dist/styles.css";

class ImagesSliderComponent extends Component {
  constructor() {
    super();
    this.slideRef = React.createRef();
    this.back = this.back.bind(this);
    this.next = this.next.bind(this);
    this.state = {
      current: 0,
    };
  }

  back() {
    this.slideRef.current.goBack();
  }

  next() {
    this.slideRef.current.goNext();
  }

  render() {
    const properties = {
      duration: 5000,
      autoplay: true,
      transitionDuration: 500,
      arrows: true,
      infinite: true,
      easing: "ease",
      indicators: (i) => <div className="indicator">{i + 1}</div>,
    };
    const slideImages = [
      "https://firebasestorage.googleapis.com/v0/b/my-apartment-4dbe1.appspot.com/o/villa-julija%2F109558432.jpg?alt=media&token=6aaa8a20-0df2-46e8-8471-32d3fb638019",
      "https://firebasestorage.googleapis.com/v0/b/my-apartment-4dbe1.appspot.com/o/villa-julija%2F109563294.jpg?alt=media&token=1b1afdd2-c191-45a2-9d59-52cebac44e17",
      "https://firebasestorage.googleapis.com/v0/b/my-apartment-4dbe1.appspot.com/o/villa-julija%2F109559129.jpg?alt=media&token=7d154891-14ba-490a-97bb-92bbfb4ee352",
      "https://firebasestorage.googleapis.com/v0/b/my-apartment-4dbe1.appspot.com/o/villa-julija%2F109559114.jpg?alt=media&token=399e2ed0-7f47-4d5b-8470-8257b61de69e",
      "https://firebasestorage.googleapis.com/v0/b/my-apartment-4dbe1.appspot.com/o/villa-julija%2F109559107.jpg?alt=media&token=03f7d0e4-d059-4609-9c30-cef69b3df636",
      "https://firebasestorage.googleapis.com/v0/b/my-apartment-4dbe1.appspot.com/o/villa-julija%2F109559096.jpg?alt=media&token=b74b3fcc-487c-4675-871e-b25515bdd015",
      "https://firebasestorage.googleapis.com/v0/b/my-apartment-4dbe1.appspot.com/o/villa-julija%2F109559088.jpg?alt=media&token=23c389e9-f868-44f1-977e-18ffcdd23880",
      "https://firebasestorage.googleapis.com/v0/b/my-apartment-4dbe1.appspot.com/o/villa-julija%2F109559074.jpg?alt=media&token=1f02c586-91ad-4970-a4d4-c0a1f7e9daba",
      "https://firebasestorage.googleapis.com/v0/b/my-apartment-4dbe1.appspot.com/o/villa-julija%2F109559061.jpg?alt=media&token=e71448ea-c3ee-428d-8697-d7418caa7de2",
      "https://firebasestorage.googleapis.com/v0/b/my-apartment-4dbe1.appspot.com/o/villa-julija%2F109558853.jpg?alt=media&token=d6ae960f-0397-4a25-a7c5-8f4c538a9170",
      "https://firebasestorage.googleapis.com/v0/b/my-apartment-4dbe1.appspot.com/o/villa-julija%2F109558426.jpg?alt=media&token=41310495-174f-4e51-8d5d-7eeb3caf6950",
    ];
    return (
      <div className="images-slider-component">
        <h5>gallery</h5>
        <div className="slide-container">
          <Slide ref={this.slideRef} {...properties}>
            {slideImages.map((each, index) => (
              <div key={index} className="each-slide">
                <img className="lazy" src={each} alt="villa julija image" />
              </div>
            ))}
          </Slide>
        </div>
      </div>
    );
  }
}

export default ImagesSliderComponent;
