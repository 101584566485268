import { useEffect, useState } from "react";
import "./house-stats-component.css";

const HouseStatsComponent = () => {
  function callbackFunc(entries, observer) {
    entries.forEach((entry) => {
      var txt = entry.target.id + " visibility: " + entry.isIntersecting;
    });
  }

  let options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.3,
  };

  useEffect(() => {
    let observer = new IntersectionObserver(callbackFunc, options);

    observer.observe(document.querySelector(".stat-container h5"));
  }, []);

  return (
    <div className="house-stats">
      <div className="house-numbers">
        <div className="stat-container">
          <h5>15</h5>
          <p>people</p>
        </div>
        <div id="stat-container" className="stat-container">
          <h5>5</h5>
          <p>rooms</p>
        </div>
        <div className="stat-container">
          <h5>3</h5>
          <p>bathrooms</p>
        </div>
        <div className="stat-container">
          <h5>3</h5>
          <p>kitchens</p>
        </div>
      </div>

      <div className="house-rating">
        <h3>perfect rating</h3>
        <h5>10.0</h5>
        <p>booking and airbnb</p>
      </div>
    </div>
  );
};

export default HouseStatsComponent;
