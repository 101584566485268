import FooterComponent from "../components/footer/FooterComponent";
import HomeWelcomeComponent from "../components/home/HomeWelcomeComponent";
import HomeFeaturesLayout from "../layouts/home-feature/HomeFeaturesLayout";
import TesitmonialsLayout from "../layouts/testimonials/TestimonialsLayout";

const HomePage = () => {
  return (
    <div>
      <HomeWelcomeComponent />
      <HomeFeaturesLayout />
      <TesitmonialsLayout />
      <FooterComponent />
    </div>
  );
};

export default HomePage;
