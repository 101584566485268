import WelcomeAboutComponent from "../components/about/WelcomeAboutComponent";
import ImagesSliderComponent from "../components/slider/ImagesSliderComponent";
import HomeFeatureComponent from "../components/home/HomeFeatureComponent";
import kastelaImage from "../images/kastela.svg";
import HouseStatsComponent from "../components/about/HouseStatsComponent";
import FooterComponent from "../components/footer/FooterComponent";
import LocationComponent from "../components/location/LocationComponent";

const AboutPage = () => {
  return (
    <div>
      <WelcomeAboutComponent />

      <HomeFeatureComponent
        title="Location"
        desc="Kaštel Lukšić is a charming town located in the Split-Dalmatia County of Croatia. Set amidst beautiful natural surroundings, this town is known for its rich history and cultural heritage. Visitors can explore the town's medieval castle, or visit one of the many churches and monasteries that dot the landscape. Kaštel Lukšić is also home to a number of beaches, including the popular Kašjuni Beach, where you can swim in the crystal-clear waters of the Adriatic Sea. The town is conveniently located just a short distance from Split, making it an ideal base for exploring the region. With its rich history, beautiful beaches, and picturesque setting, Kaštel Lukšić is a must-visit destination in Croatia."
        image={kastelaImage}
        imageFirst={true}
        first={true}
      />

      <LocationComponent />

      <ImagesSliderComponent />

      <HouseStatsComponent />

      <FooterComponent />
    </div>
  );
};

export default AboutPage;
