import React from "react";
import GoogleMapReact from "google-map-react";
import "./location-component.css";
import LocationPin from "./LocationPin";

const LocationComponent = (props) => {
  let location = {
    address: "Villa Julija",
    lat: 43.55599900639759,
    lng: 16.356489153473863,
  };

  return (
    <div className="map-container">
      <h2>Villa's Location</h2>

      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAcjyfapnPGxmtqKtgDgXKQmmMwCi9l5A0" }}
          defaultCenter={location}
          defaultZoom={15}
        >
          <LocationPin lat={location.lat} lng={location.lng} />
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default LocationComponent;
