import "./navbar-component.css";
import hamburgerMenuImage from "../../images/hamburger-menu.svg";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import close from "../../images/close.png";

const NavBarComponent = () => {
  let activeStyle = {
    color: "#B98860",
    fontFamily: "charter",
    fontWeight: 700,
  };

  const [navHeight, setNavHeight] = useState("auto");

  const [hamburgerStyle, setHamburgerStyle] = useState({ display: "none" });

  const onHamburgerMenuClick = () => {
    console.log(hamburgerStyle);

    if (hamburgerStyle.display == "none") {
      setHamburgerStyle({ display: "block" });
      setNavHeight("100vh");
      disableBodyScroll(document);
    } else {
      setHamburgerStyle({ display: "none" });
      setNavHeight("auto");
      enableBodyScroll(document);
    }
  };

  return (
    <div className="navbar" style={{ height: navHeight }}>
      <nav>
        <div className="top">
          <div className="left">
            <h1>Villa Julija</h1>
            <h3>Kaštela, Croatia</h3>
          </div>

          <div className="right">
            <ul>
              <li>
                <NavLink
                  to="/"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Home
                </NavLink>
              </li>
              <li>/</li>
              <li>
                <NavLink
                  to="/book"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Book
                </NavLink>
              </li>
              <li>/</li>
              <li>
                <NavLink
                  to="/about"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  About
                </NavLink>
              </li>
            </ul>
            <img
              src={hamburgerMenuImage}
              alt="image to open villa julija pages menu"
              onClick={onHamburgerMenuClick}
            />
          </div>
        </div>
      </nav>
      <div id="hamburger-menu" style={hamburgerStyle}>
        <img
          src={close}
          alt="image to close villa julija pages menu"
          onClick={onHamburgerMenuClick}
        />
        <ul>
          <li>
            <NavLink onClick={onHamburgerMenuClick} to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink onClick={onHamburgerMenuClick} to="/book">
              Book
            </NavLink>
          </li>
          <li>
            <NavLink onClick={onHamburgerMenuClick} to="about">
              About
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="nav-divider"></div>
    </div>
  );
};

export default NavBarComponent;
