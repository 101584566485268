import "./check-availability-component.css";

const CheckAvailabilityComponent = (params) => {
  return (
    <div className="check-availability">
      <h5>check availability for villa julija</h5>
      <div className="details">
        <p>{"Check-In: " + params.checkIn}</p>
        <p>{"Check-Out: " + params.checkOut}</p>
        <p>{"Adults: " + params.adults}</p>
        <p>{"Children: " + params.children}</p>
      </div>
    </div>
  );
};

export default CheckAvailabilityComponent;
