import featureOneImage from "../../images/home-feature-1.svg";
import summerKitchenImage from "../../images/summer-kitchen.jpeg";
import garageImage from "../../images/garage.jpeg";
import HomeFeatureComponent from "../../components/home/HomeFeatureComponent";
import "./home-features-layout.css";

const HomeFeaturesLayout = () => {
  return (
    <div className="home-features">
      <h1>Contents - Pool, Outdoor kitchen, grill, garage</h1>
      <HomeFeatureComponent
        title="Pool"
        desc="Private modern swimming pool with an entrance suitable for children and the elderly. With 8m length, 4m width and 1.5m depth, pool will satisfy all your needs, be it swimming or jumping. You can also sunbathe on flamingo or deck chairs."
        image={featureOneImage}
        imageFirst={true}
        first={true}
      />
      <HomeFeatureComponent
        title="Grill with an outdoor kitchen"
        desc="A place where you can enjoy grilled food, be it fish or meat. Outdoor kitchen with 2 large tables and enough space for all household members. Relax by watching a movie or listening to music on the outdoor TV."
        image={summerKitchenImage}
        imageFirst={false}
        first={false}
      />
      <HomeFeatureComponent
        title="Garage for vehicles with entertainment facilities"
        desc="This garage isn't just a place to store your vehicles, it's also a versatile recreation space. Inside, you'll find a table soccer setup, a range of gym equipment, and a table tennis table for a game of ping pong. Additionally, there is a darts board where you can practice your aim and play a round with friends. With all of these activities available, this garage is the perfect spot for a fun-filled afternoon with the family. Whether you want to work up a sweat on the gym equipment or challenge someone to a game of table soccer, there's something for everyone in this multi-purpose space."
        image={garageImage}
        imageFirst={true}
        first={false}
      />
    </div>
  );
};

export default HomeFeaturesLayout;
