import "./App.css";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { useLayoutEffect } from "react";
import NavBarComponent from "./components/navbar/NavBarComponent";
import HomePage from "./pages/HomePage";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import BookPage from "./pages/BookPage";
import AboutPage from "./pages/AboutPage";

const firebaseConfig = {
  apiKey: "AIzaSyDwD-dxKQvhZoc1Tiy9-Eunb1pNUx9c6eU",
  authDomain: "my-apartment-4dbe1.firebaseapp.com",
  projectId: "my-apartment-4dbe1",
  storageBucket: "my-apartment-4dbe1.appspot.com",
  messagingSenderId: "1083765226237",
  appId: "1:1083765226237:web:321381bc0cd286af34dc94",
  measurementId: "G-ZVCKKSDN5X",
};

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
getAnalytics(app);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBarComponent />
        <Wrapper>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/book" element={<BookPage />} />
            <Route path="about" element={<AboutPage />} />
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
