import "./footer-component.css";
import ferryImage from "../../images/ferry.svg";
import planeImage from "../../images/plane.svg";
import linkImage from "../../images/link.svg";

const FooterComponent = () => {
  return (
    <div>
      <div className="footer-component">
        <div className="footer-item">
          <h5>travel</h5>
          <div className="travel-items">
            <div className="travel-item">
              <img src={ferryImage} alt="ferry" />
              <a href="https://www.jadrolinija.hr/en/about-us/ships/ferries">
                ferry
              </a>
            </div>
            <div className="travel-item">
              <img src={planeImage} alt="plane" />
              <a href="http://www.split-airport.hr/">flights</a>
            </div>
          </div>
        </div>
        <div className="footer-item">
          <h5>nightlife</h5>
          <ul>
            <li>
              <a href="https://notte.puzzle-agency.com">Notte</a>
            </li>
            <li>
              <a href="https://visitsplit.com">VisitSplit</a>
            </li>
          </ul>
        </div>
        <div className="footer-item">
          <h5>house</h5>
          <div className="house-item">
            <img src={linkImage} alt="link" />
            <p>house rules</p>
          </div>
          <div className="house-item">
            <img src={linkImage} alt="link" />
            <p>rulebook</p>
          </div>
        </div>
        <div className="footer-item">
          <h5>contact</h5>
          <div className="contact-item">
            <img src={linkImage} alt="link" />
            <a href="mailto:miro.vladusic2@gmail.com">Email</a>
          </div>
          <div className="contact-item">
            <img src={linkImage} alt="link" />
            <a>+385 95 34 44762</a>
          </div>
        </div>
      </div>
      <div className="signature-container">
        <p>
          {"© " +
            new Date().getFullYear() +
            " Villa Julija, All rights reserved"}
        </p>
      </div>
    </div>
  );
};

export default FooterComponent;
