import markerImage from "../../images/marker.png";

const LocationPin = () => {
  return (
    <img
      style={{ width: "70px", height: "70px", marginBottom: "35px" }}
      src={markerImage}
      alt="marker image that shows villa julija location"
    />
  );
};

export default LocationPin;
